import axios from "axios";
import { getAuth } from "firebase/auth";

axios.defaults.headers.common["ngrok-skip-browser-warning"] = "true";

const API_URL = "https://back.pickuplandia.com/api";

export const getTest = async () => {
  const { data } = await axios.get(`${API_URL}/test-db`);

  return data;
};

export const postCar = async (carParams) => {
  const auth = getAuth();
  const token = await auth.currentUser.getIdToken();

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return axios.post(`${API_URL}/cars`, carParams, config);
};
export const getAllCars = async () => {
  return axios.get(`${API_URL}/cars`);
};

export const getSomeCars = async (
  page,
  carsPerPage,
  filters = {},
  fromAdminDashboard = false,
) => {
  try {
    const response = await axios.post(`${API_URL}/someCars`, {
      page,
      carsPerPage,
      filters, // Envía los filtros al backend
      fromAdminDashboard,
    });
    return response.data; // Retorna los datos del carro
  } catch (error) {
    console.error("Error al obtener algunos carros:", error);
    throw error; // Lanza el error para manejarlo en el componente
  }
};

export const getCountCars = async (filters, fromAdminDashboard = false) => {
  try {
    const response = await axios.post(`${API_URL}/carsCount`, {
      filters,
      fromAdminDashboard,
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener el conteo de carros:", error);
    throw error;
  }
};

export const getCarById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/comprar-auto/${id}`);
    return response.data; // Retorna los datos del auto
  } catch (error) {
    console.error("Error al obtener el auto por ID:", error);
    throw error; // Lanza el error para manejarlo en el componente
  }
};
export const updateCar = async (id, carData) => {
  try {
    const response = await axios.put(
      `${API_URL}/admin/editar-auto/${id}`,
      carData,
    );
    return response.data; // Retorna la respuesta de la actualización del auto
  } catch (error) {
    console.error("Error al actualizar el auto:", error);
    throw error; // Lanza el error para manejarlo en el componente
  }
};

export const deleteCar = async (id) => {
  return axios.delete(`${API_URL}/cars/${id}`);
};

export const getAllMarcas = async () => {
  return axios.get(`${API_URL}/marcas`);
};

export const getAllSubmarcas = async () => {
  return axios.get(`${API_URL}/submarcas`);
};

export const getAllModelos = async () => {
  return axios.get(`${API_URL}/modelos`);
};

export const getAllVersiones = async () => {
  return axios.get(`${API_URL}/versiones`);
};

export const getAllColores = async () => {
  return axios.get(`${API_URL}/colores`);
};

export const getAllTracciones = async () => {
  return axios.get(`${API_URL}/tracciones`);
};

export const getAllTransmisiones = async () => {
  return axios.get(`${API_URL}/transmisiones`);
};

export const getAllCombustibles = async () => {
  return axios.get(`${API_URL}/combustibles`);
};

export const getAllMarcasSubmarcas = async () => {
  try {
    const response = await axios.get(`${API_URL}/marcasSubmarcas`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener marcas y submarcas:", error);
    return [];
  }
};

export const getAllUbicaciones = async () => {
  return axios.get(`${API_URL}/ubicaciones`);
};

export const getPrecioMax = async () => {
  try {
    const response = await axios.get(`${API_URL}/precioMax`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener el precio máximo:", error);
  }
};
export const getPrecioMin = async () => {
  try {
    const response = await axios.get(`${API_URL}/precioMin`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener el precio minimo:", error);
  }
};

export const getKmMax = async () => {
  try {
    const response = await axios.get(`${API_URL}/kmMax`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener el kilometraje máximo:", error);
  }
};
export const getKmMin = async () => {
  try {
    const response = await axios.get(`${API_URL}/kmMin`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener el kilometraje minimo:", error);
  }
};

export const injectCarTest = async (carParams) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  if (!currentUser) {
    throw new Error("No hay usuario autenticado");
  }

  const token = await currentUser.getIdToken();

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  // Cambia la URL a /carsTest para coincidir con tu endpoint de pruebas
  return axios.post(`${API_URL}/carsTest`, carParams, config);
};

export const getRandomCars = async (count) => {
  try {
    const response = await axios.get(`${API_URL}/random-cars/${count}`);
    return response.data;
  } catch (error) {
    console.error("Could not fetch random cars:", error);
    throw error;
  }
};

export const getAllCarrocerias = async () => {
  return axios.get(`${API_URL}/carrocerias`);
};

export const getCarrosInteresByCarroceria = async (carId, n) => {
  try {
    const response = await axios.get(`${API_URL}/carros-interes/${carId}/${n}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching carros de interés por carroceria:", error);
    throw error;
  }
};

export async function sendContactForm(data) {
  try {
    const response = await fetch(`${API_URL}/send-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("No se pudo enviar el formulario.");
    }

    return await response.json();
  } catch (error) {
    console.error("Error al enviar el formulario:", error);
    throw error;
  }
}

// Función para enviar las URLs de las imágenes a conservar
export const deleteCarImages = async (carId, imagesToDelete) => {
  try {
    const response = await axios.post(
      `${API_URL}/cars/${carId}/delete-images`,
      {
        imagesToDelete,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error al eliminar imágenes:", error);
    throw error;
  }
};
