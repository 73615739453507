import { Alert, Button, Snackbar, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MyNavbar from "../components/NavBar";
import Footer from "../components/Footer";
import SpinnerLlanta from "../components/SpinnerLlanta";
import { sendContactForm } from "../api";
import { useLocation } from "react-router-dom";

const Contacto = () => {
  // Estados para cada campo del formulario
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");

  const location = useLocation();
  const [mensaje, setMensaje] = useState("");

  const [loading, setLoading] = useState(false); // Estado para controlar la visibilidad del spinner
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Estado para controlar la visibilidad del Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Mensaje para el Snackbar
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Severidad del mensaje

  const ejemplosTextos = [
    "Ejemplo: Me gustaria mas informe sobre ...",
    "Ejemplo: ¿Qué requisitos hay para el financiamiento?",
    "Ejemplo: Estoy buscando un vehículo para uso de carga",
    "Ejemplo: Me gustaría agendar una cita para ver el vehículo",
    "Ejemplo: Estoy buscando una camioneta para toda la familia",
    "Ejemplo: Estoy buscando vender un auto",
  ];

  // Estado para almacenar el texto seleccionado
  const [placeholderTexto, setPlaceholderTexto] = useState("");

  useEffect(() => {
    const textoSeleccionado =
      ejemplosTextos[Math.floor(Math.random() * ejemplosTextos.length)];

    // Si el estado de navegación incluye un mensaje, lo usa para inicializar el campo del formulario
    if (location.state?.mensaje) {
      setMensaje(location.state.mensaje);
    }

    setPlaceholderTexto(textoSeleccionado);
  }, [location]);

  const validateForm = () => {
    // Verificar que todos los campos estén llenos
    if (!nombre.trim() || !telefono.trim() || !mensaje.trim()) {
      setSnackbarMessage("Todos los campos excepto correo son obligatorios.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    }

    // Verificar que el teléfono contenga solo números
    if (!/^\d+$/.test(telefono)) {
      setSnackbarMessage("El teléfono debe contener solo números.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return false;
    }

    return true; // Si pasa todas las validaciones
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return; // Detiene la función si la validación falla

    setLoading(true); // Muestra el spinner

    const formData = {
      nombre,
      telefono,
      correo,
      mensaje,
    };

    sendContactForm(formData)
      .then((response) => {
        setSnackbarMessage("Correo enviado exitosamente");
        setSnackbarSeverity("success");
        // Limpia el formulario
        setNombre("");
        setCorreo("");
        setMensaje("");
        setTelefono("");
        setLoading(false); // Oculta el spinner
        setSnackbarOpen(true); // Muestra el Snackbar
      })
      .catch((error) => {
        setLoading(false); // Oculta el spinner
        setSnackbarMessage("Error al enviar el formulario");
        setSnackbarSeverity("error");
        setSnackbarOpen(true); // Muestra el Snackbar
      });
  };

  // Ajustes para los manejadores de eventos onChange
  const handleNombreChange = (e) => setNombre(e.target.value);
  const handleTelefonoChange = (e) => setTelefono(e.target.value);
  const handleCorreoChange = (e) => setCorreo(e.target.value);
  const handleMensajeChange = (e) => setMensaje(e.target.value);
  const handleCloseSnackbar = () => setSnackbarOpen(false);

  let whatsAppMessage = "Hola, estoy interesado en: ";
  // Codifica el mensaje para URL
  whatsAppMessage = encodeURIComponent(whatsAppMessage);
  // Construye la URL completa para WhatsApp
  whatsAppMessage = `https://wa.me/+525519729384?text=${whatsAppMessage}`;

  return (
    <div className="contacto">
      <MyNavbar />
      <div className="content">
        <Typography
          className="texto"
          fontWeight="fontWeightBold"
          fontSize="2.5rem"
          variant="h3"
        >
          ¡Ponte en contacto con nosotros!
        </Typography>
        <div className="columns">
          <div className="formulario">
            {loading ? (
              <SpinnerLlanta /> // Muestra el spinner si loading es true
            ) : (
              <>
                <Typography className="texto" fontSize="2rem" variant="h4">
                  ¿Quieres vender tu auto o tienes alguna duda?
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    color="rojoYBlanco"
                    className="textField"
                    name="Nombre"
                    label="Nombre"
                    value={nombre}
                    onChange={handleNombreChange}
                    required
                  ></TextField>
                  <TextField
                    color="rojoYBlanco"
                    className="textField"
                    name="Telefono"
                    label="Telefono"
                    value={telefono}
                    onChange={handleTelefonoChange}
                    required
                  ></TextField>
                  <TextField
                    color="rojoYBlanco"
                    className="textField"
                    name="Correo"
                    label="Correo Electronico"
                    value={correo}
                    onChange={handleCorreoChange}
                  ></TextField>
                  <TextField
                    color="rojoYBlanco"
                    className="textField"
                    name="Mensaje"
                    label="Mensaje"
                    value={mensaje}
                    onChange={handleMensajeChange}
                    multiline
                    rows={4}
                    placeholder={placeholderTexto}
                    required
                  ></TextField>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="negroYBlanco"
                  >
                    Enviar
                  </Button>
                </form>
              </>
            )}
          </div>
          <div className="banner">
            <img src="/images/imgContacto.png" alt="" />
            <Typography className="texto" variant="h5">
              También puedes dejarnos un mensaje por Whatsapp o llamarnos por
              télefono.
            </Typography>

            <div className="botones">
              <Button
                size="large"
                variant="outlined"
                color="negroYNegro"
                href={whatsAppMessage}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textTransform: "none",
                  "&:hover": {
                    color: "#bd2128", // Cambia el color del texto al pasar el ratón
                    border: "#bd2128 1px solid",
                  },
                }}
              >
                Whatsapp
              </Button>
              <Button
                size="large"
                variant="outlined"
                color="negroYNegro"
                href={`tel:5519729384`}
                sx={{
                  textTransform: "none",
                  "&:hover": {
                    color: "#bd2128", // Cambia el color del texto al pasar el ratón
                    border: "#bd2128 1px solid",
                  },
                }}
              >
                ¡Llamanos!
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Contacto;
