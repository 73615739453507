import React, { useState, useEffect } from "react";

export const Timer = ({ timeoutPeriod, onTimeout }) => {
  const [timeLeft, setTimeLeft] = useState(timeoutPeriod);

  useEffect(() => {
    // Update the timer every second
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        const newTime = prevTime - 1000;
        if (newTime <= 0) {
          clearInterval(interval);
          onTimeout();
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [onTimeout]);

  // Format the time left for display
  const minutes = Math.floor(timeLeft / 60000);
  const seconds = ((timeLeft % 60000) / 1000).toFixed(0);

  return (
    <div>
      Time remaining: {minutes}:{seconds < 10 ? "0" : ""}
      {seconds}
    </div>
  );
};
