import React, { useEffect, useState } from "react";

const RangeSelector = ({ onRangeChange, maxValue, unit = "", step = 1000 }) => {
  // Estado inicial para los valores mínimos y máximos del rango
  const [range, setRange] = useState({
    min: 0, // Considerando que el mínimo siempre es 0
    max: maxValue, // Usa maxValue pasado como prop
  });

  // Actualiza el estado cuando maxValue cambia
  useEffect(() => {
    setRange((prevRange) => ({
      ...prevRange,
      max: maxValue, // Asegura que el máximo se actualice con el nuevo maxValue
    }));
  }, [maxValue]); // Este efecto se ejecuta cada vez que maxValue cambia

  // Manejador para cambios en los controles deslizantes
  const handleRangeChange = (e) => {
    const { name, value } = e.target;
    const updatedRange = { ...range, [name]: Number(value) };
    if (name === "min" && Number(value) < range.max) {
      setRange(updatedRange);
      // Llama a onRangeChange para informar al padre sobre el cambio
      onRangeChange(updatedRange);
    }
    if (name === "max" && Number(value) > range.min) {
      setRange(updatedRange);
      // Llama a onRangeChange para informar al padre sobre el cambio
      onRangeChange(updatedRange);
    }
  };

  const handleStepChanger = () => {
    if (step > maxValue - range.max) {
      step = step / 2;
    }
  };

  return (
    <div className="RangeSelectorContainer">
      <div className="card-content">
        <div className="values">
          <div>
            {unit}
            <span id="range-min">{range.min}</span>
          </div>{" "}
          -
          <div>
            {unit}
            <span id="range-max">{range.max}</span>
          </div>
        </div>
        <small className="current-range">
          Seleccionado:
          <div>
            <span id="range-current">
              {range.min} - {range.max} {unit}
            </span>
          </div>
        </small>
        <div className="rangeslider">
          <input
            className="min input-ranges"
            name="min"
            type="range"
            step={step}
            min="0"
            max={maxValue}
            value={range.min}
            onChange={handleRangeChange}
          />
          <input
            className="max input-ranges"
            name="max"
            type="range"
            step={handleStepChanger}
            min="0"
            max={maxValue}
            value={range.max}
            onChange={handleRangeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default RangeSelector;
