// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB7vaw5PBNy5nUDvmxiwOgB_ZDHKkF86Cw",
  authDomain: "webpickuplandia.firebaseapp.com",
  projectId: "webpickuplandia",
  storageBucket: "webpickuplandia.appspot.com",
  messagingSenderId: "807101489850",
  appId: "1:807101489850:web:5ea81f5a7eb21645521883",
  measurementId: "G-VYL5MPQRQ6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firestore
const firestore = getFirestore(app);

// Initialize Firebase Storage
const storage = getStorage(app);

const auth = getAuth(app);

export { firestore, storage, auth };
