// src/components/Footer.js
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link } from "@mui/material";
import React from "react";

const Footer = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
      <div className="footer">
        <div className="section">
          <h3>Redes Sociales</h3>
          <div className="redesSociales">
            <a
              style={{
                fontSize: "16px",
                padding: "5px",
                textDecoration: "none",
              }}
              href="https://www.facebook.com/profile.php?id=100094292467632&mibextid=JRoKGi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon color="negroYBlanco" fontSize="large" />
              <div>
                <div className="liga">Pick Uplandia SA de CV</div>
                <div style={{ textDecoration: "none", color: "black" }}>
                  +2.7k Followers
                </div>
              </div>
            </a>
            <a
              style={{
                fontSize: "16px",
                padding: "5px",
                textDecoration: "none",
              }}
              href="https://wa.me/+525519729384"
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsAppIcon color="negroYBlanco" fontSize="large" />
              <div>
                <div className="liga">WhatsApp</div>
                <div style={{ textDecoration: "none", color: "black" }}>
                  +52 55-1972-9384
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="section2">
          <h3 style={{ display: "flex", justifyContent: "center" }}>
            Contacto
          </h3>
          <div style={{ display: "flex", flexWrap: "wrap", gap: ".5rem" }}>
            <div>
              <p
                style={{
                  fontWeight: "700",
                }}
              >
                Correo
              </p>
              <p>contacto@pickuplandia.com</p>
            </div>
            <div>
              <p style={{ fontWeight: "700" }}>Centenario</p>
              <p>+52 55-7158-1517</p>
              <p>+52 55-7158-0234</p>
              <p>+52 55-5214-2890</p>
              <p style={{ fontWeight: "700" }}>Tlanepantla</p>
              <p>+52 55-5390-7328</p>
              <p>+52 55-5175-6654</p>
              <p>+52 55-9109-1010</p>
            </div>
          </div>
        </div>
        <div className="section">
          <h3>Encuéntranos en</h3>
          <p style={{ fontWeight: "700" }}>Centenario</p>
          <p
            onClick={() =>
              openInNewTab("https://maps.app.goo.gl/E6FFBNuxg47j9ERt8")
            }
          >
            Avenida Centenario 1002, La Martinica, Álvaro Obregón, C.P. 01619
            Ciudad de México, México.
          </p>
          <p style={{ fontWeight: "700" }}>Tlalnepantla</p>
          <p
            onClick={() =>
              openInNewTab("https://maps.app.goo.gl/T6ig1JP93RJkCKss8")
            }
          >
            Av. Mario Colin 8-A Colonia San Javier, Tlalnepantla de Baz, Estado
            de México, C.P 54030
          </p>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#e6e6e6",
          display: "flex",
          width: "100%",
          paddingBottom: "1rem",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link
          sx={{
            backgroundColor: "#e6e6e6",
            display: "flex",
            width: "fit-content",
            justifyContent: "center",
            textDecoration: "none",
            color: "black",
          }}
          href={`/AvisoDePrivacidad.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Aviso de Privacidad
        </Link>
      </div>
    </div>
  );
};

export default Footer;

/*Redes Sociales
          <a
            style={{ fontSize: "30px", padding: "5px" }}
            href="https://instagram.com"
          >
            <InstagramIcon color="negroYBlanco" />
          </a>
          <a
            style={{ fontSize: "30px", padding: "5px" }}
            href="https://twitter.com"
          >
            <XIcon color="negroYBlanco" />
          </a>
*/

/*<div className="section">
        <h3>Suscríbete a nuestro newsletter</h3>
        <p>
          Regístrate para recibir lo último sobre noticias, nuevos lanzamientos
          y más
        </p>
        <form className="form" onSubmit={handleSubmit} autoComplete="off">
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            label="Ingresa tu correo electronico"
            variant="outlined"
            size="small"
            color="negroYNegro"
          />
          <Button
            type="submit"
            className="button"
            variant="contained"
            color="negroYBlanco"
          >
            Registrarse
          </Button>
        </form>
      </div>*/
