import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "./Firebase";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Tiempo de inactividad antes de cerrar sesión (en milisegundos)
  // Por ejemplo, 30 minutos: 30 * 60 * 1000
  const timeoutPeriod = 1800000;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);

      if (user) {
        // Establecer un temporizador para cerrar sesión después del período de tiempo
        setTimeout(() => {
          // Implementar lógica de cierre de sesión aquí
          auth.signOut();
          // Opcionalmente, redirigir al usuario a la página de inicio de sesión
        }, timeoutPeriod);
      }
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
