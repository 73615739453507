import { createTheme } from "@mui/material";
import { dark, light } from "@mui/material/styles/createPalette";

const mainTheme = createTheme({
  palette: {
    blancoYNegro: {
      main: "#ffffff",
      light: "#b2b2b2",
      dark: "#ffffff",
      contrastText: "#000000",
    },
    rojoYBlanco: {
      main: "#bd2128",
      contrastText: "#ffffff",
      light: "#bd2128",
    },
    blancoYRojo: {
      main: "#ffffff",
      contrastText: "#bd2128",
    },
    rojoYNegro: {
      main: "#bd2128",
      contrastText: "#000000",
    },
    negroYBlanco: {
      main: "#000000",
      contrastText: "#ffffff",
    },
    negroYNegro: {
      main: "#000000",
      contrastText: "#000000",
    },
  },
  typography: {
    fontFamily: "Tasa",
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightBold: 700,
  },
});

export { mainTheme };
