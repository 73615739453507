import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Drawer, IconButton, List, ListItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";

const MyNavbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:595px)");

  const links = [
    { href: "/comprar-auto", label: "Compra un Auto" },
    { href: "/ubicacion", label: "Ubicacion" },
    //Proximamente, aun se puede acceder pero no mediante botones
    //{ href: "/contenido", label: "Contenido Adicional" },
    { href: "/contacto", label: "Contacto" },
  ];

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <div className="nav">
      <NavLink to="/" style={{ backgroundColor: "white" }}>
        <img className="nav-logo" src="/logo-01.png" alt="logo uwu" />
      </NavLink>
      {isMobile ? (
        <>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            className="menu-icon"
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            <List style={{ width: "250px", paddingTop: "80px" }}>
              {links.map(({ href, label }) => (
                <ListItem button key={href} onClick={toggleDrawer(false)}>
                  <NavLink
                    to={href}
                    className={({ isActive }) =>
                      isActive ? "nav-links-active" : "nav-links"
                    }
                  >
                    {label}
                  </NavLink>
                </ListItem>
              ))}
            </List>
          </Drawer>
        </>
      ) : (
        <div className="nav-boxLinks">
          {links.map(({ href, label }) => (
            <NavLink
              key={href}
              className={({ isActive }) =>
                isActive ? "nav-links-active" : "nav-links"
              }
              to={href}
            >
              {label}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default MyNavbar;
