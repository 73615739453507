import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Snackbar,
  CircularProgress,
  Typography,
  Switch,
} from "@mui/material";
import { storage } from "../../firebase/Firebase";
import { initialCarState } from "../../carState";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import FileInput from "../../components/FileInput";
import {
  getAllMarcas,
  getAllSubmarcas,
  getAllModelos,
  getAllVersiones,
  getAllColores,
  getAllCombustibles,
  getAllTracciones,
  getAllTransmisiones,
  getAllUbicaciones,
  injectCarTest,
  postCar,
  getAllCarrocerias,
} from "../../api";
import AdminNavbar from "../../components/AdminNavBar";
import AutoCompleteField from "../../components/AutoCompleteField";

const AgregarAuto = () => {
  const [car, setCar] = useState(initialCarState);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // Añade un estado para manejar una clave de reinicio para los AutoCompleteFields
  const [resetKey, setResetKey] = useState(0);

  //Autocompletado de Textfields, aqui se almacenaran todos los valores posibles para cada campo
  const [opciones, setOpciones] = useState({
    marca: [],
    submarca: [],
    version: [],
    modelo: [],
    color: [],
    transmision: [],
    combustible: [],
    traccion: [],
    ubicacion: [],
    carroceria: [],
    // Añadir más campos si es necesario
  });
  // Lista de funciones para solicitar datos de la API
  const funcionesAPI = [
    getAllMarcas,
    getAllSubmarcas,
    getAllModelos,
    getAllVersiones,
    getAllColores,
    getAllTransmisiones,
    getAllCombustibles,
    getAllTracciones,
    getAllUbicaciones,
    getAllCarrocerias,
  ];

  // Lista de claves para actualizar en el estado 'opciones'
  const claves = [
    "marca",
    "submarca",
    "modelo",
    "version",
    "color",
    "transmision",
    "combustible",
    "traccion",
    "ubicacion",
    "carroceria",
  ];

  useEffect(() => {
    const cargarOpciones = async () => {
      try {
        for (const [i, funcionAPI] of funcionesAPI.entries()) {
          (async function () {
            const respuesta = await funcionAPI();
            if (respuesta && Array.isArray(respuesta.data)) {
              let data = respuesta.data.filter(
                (item) => item.name.trim() !== "",
              );
              setOpciones((prev) => ({
                ...prev,
                [claves[i]]: prev[claves[i]].length ? prev[claves[i]] : data,
              }));
            } else {
              console.error(`Respuesta no válida de la API para ${claves[i]}`);
              // Manejar adecuadamente una respuesta inesperada o falta de datos
            }
          })();
        }
      } catch (error) {
        console.error("Error al cargar opciones:", error);
      }
    };

    cargarOpciones();
  }, []);

  useEffect(() => {}, [opciones]);

  const handleAutocompleteChange = (name) => (value) => {
    setCar((prevCar) => ({
      ...prevCar,
      general: { ...prevCar.general, [name]: value },
    }));
  };

  // Modificar la función createTextFields para manejar estructuras anidadas
  /*const createTextFields = (data, parentKey = "") => {
    return Object.entries(data).map(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        // Si es un objeto anidado, llama recursivamente a createTextFields
        return (
          <div className="detallesAdicionales" key={key}>
            <Typography variant="h6" style={{ margin: "10px 0" }}>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </Typography>
            <div className="subcampos">{createTextFields(value, key)}</div>
          </div>
        );
      } else {
        // Crea TextField para campos no anidados
        const fullName = parentKey ? `${parentKey}.${key}` : key; // Maneja la nomenclatura para campos anidados
        return (
          <TextField
            className="textField"
            key={fullName}
            name={fullName}
            label={key.charAt(0).toUpperCase() + key.slice(1)}
            value={value}
            onChange={handleChange}
            style={{ margin: "5px 0" }} // Estilo opcional
          />
        );
      }
    });
  };*/

  const createSwitchFields = (data, parentKey = "") => {
    return Object.entries(data).map(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        return (
          <div className="detallesAdicionales" key={key}>
            <Typography variant="h6" style={{ margin: "10px 0" }}>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </Typography>
            <div className="subcampos">{createSwitchFields(value, key)}</div>
          </div>
        );
      } else {
        const isSwitch =
          parentKey === "equipamiento" || parentKey === "seguridad";
        const fullName = parentKey ? `${parentKey}.${key}` : key;
        return isSwitch ? (
          <FormControlLabel
            control={
              <Switch
                checked={typeof value === "boolean" ? value : value === "Si"}
                onChange={(e) => handleSwitchChange(e, fullName)}
                name={fullName}
                color="rojoYBlanco"
              />
            }
            label={key.charAt(0).toUpperCase() + key.slice(1)}
            key={fullName}
          />
        ) : (
          <TextField
            className="textField"
            key={fullName}
            name={fullName}
            label={key.charAt(0).toUpperCase() + key.slice(1)}
            value={value}
            onChange={handleChange}
            style={{ margin: "5px 0" }}
            color="rojoYBlanco"
          />
        );
      }
    });
  };

  const handleSwitchChange = (event, name) => {
    const { checked } = event.target;
    const keys = name.split("."); // Dividir el nombre para campos anidados
    setCar((prevState) => ({
      ...prevState,
      [keys.length > 1 ? "detallesAdicionales" : "general"]: {
        ...prevState[keys.length > 1 ? "detallesAdicionales" : "general"],
        [keys[0]]:
          keys.length > 1
            ? { ...prevState.detallesAdicionales[keys[0]], [keys[1]]: checked }
            : checked,
      },
    }));
  };

  // Función modificada para manejar cambios en el Checkbox
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCar((prevState) => ({
      ...prevState,
      general: {
        ...prevState.general,
        [name]: checked,
      },
    }));
  };

  // Adaptar handleChange para manejar cambios en campos anidados
  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split("."); // Divide el nombre para obtener las claves de los campos anidados
    setCar((prevState) => ({
      ...prevState,
      [keys.length > 1 ? "detallesAdicionales" : "general"]: {
        ...prevState[keys.length > 1 ? "detallesAdicionales" : "general"],
        [keys[0]]:
          keys.length > 1
            ? { ...prevState.detallesAdicionales[keys[0]], [keys[1]]: value } // Actualiza el valor del campo anidado
            : value,
      },
    }));
  };

  function capitalizeWords(string) {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show loading indicator

    try {
      // Prepare car data for the API request
      const carData = {
        ...car.general,
        // Propaga cada campo anidado individualmente
        ...car.detallesAdicionales.generales,
        ...car.detallesAdicionales.exterior,
        ...car.detallesAdicionales.interior,
        ...car.detallesAdicionales.equipamiento,
        ...car.detallesAdicionales.seguridad,
      };

      Object.entries(carData).forEach(([key, value]) => {
        if (typeof value === "string") {
          // Capitaliza la primera letra de cada palabra en la cadena
          carData[key] = capitalizeWords(value);
        }
      });

      // Upload images to Firebase Storage and get their URLs
      const imageUrls =
        selectedFiles.length > 0
          ? await Promise.all(selectedFiles.map((file) => uploadImage(file)))
          : [];

      // Add image URLs to carData
      carData.images = imageUrls.filter((url) => url != null);

      // Send carData to the backend server
      await postCar(carData);

      setSnackbarMessage("Auto ha sido agregado correctamente!");
      setSnackbarOpen(true);

      // Después de enviar, actualiza el estado para restablecer los campos
      setCar(initialCarState); // Asumiendo que esto restablece el estado correctamente
      setSelectedFiles([]);
      setResetKey((prevKey) => prevKey + 1); // Incrementa la clave para forzar la recreación de los AutoCompleteFields
    } catch (error) {
      console.error("Error al agregar auto: ", error);
      setSnackbarMessage("Error al agregar auto: " + error.message);
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false); // Hide loading indicator
    }
  };

  const uploadImage = async (file) => {
    const timestamp = Date.now();
    // Construir el nuevo nombre del archivo basado en el formato deseado
    const newFileName = `${car.general.marca}-${car.general.submarca}-${timestamp}-${file.name}`;
    const storageRef = ref(storage, `cars/${newFileName}`);
    try {
      const snapshot = await uploadBytes(storageRef, file);
      return getDownloadURL(snapshot.ref);
    } catch (error) {
      console.error("Error subiendo el archivo: ", error);
      return null;
    }
  };

  //Funcion para manejar la inyeccion de un carro de prueba, SOLO para testear el llenado a la BDD
  /*
  const handleTestInjectClick = async () => {
    setIsLoading(true); // Mostrar indicador de carga
    try {
      const response = await injectCarTest(car); // Asegúrate de que 'car' contenga los parámetros esperados por 'injectCarTest'
      console.log(response); // Maneja la respuesta como necesites
      setSnackbarMessage("Car test injected successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error injecting car test: ", error);
      setSnackbarMessage("Error injecting car test: " + error.message);
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false); // Ocultar indicador de carga
    }
  };
  */

  return (
    <div>
      <AdminNavbar />
      <div className="content">
        <div className="agregarCoche">
          <h1>Agregar Carro</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <div>
                <h2>General</h2>
                <div className="textFields">
                  {Object.keys(car.general).map((key) => {
                    if (
                      key !== "km" &&
                      key !== "existence" &&
                      key !== "precio"
                    ) {
                      return (
                        <AutoCompleteField
                          key={`${key}-${resetKey}`}
                          label={key.charAt(0).toUpperCase() + key.slice(1)}
                          options={opciones[key] || []} // Asegúrate de que 'opciones[key]' no sea undefined
                          value={car[key] || ""}
                          onChange={(v) => handleAutocompleteChange(key)(v)}
                        />
                      );
                    }
                    return null; // No renderizar nada para 'km'
                  })}
                  <TextField
                    className="textField"
                    key={"km"}
                    name={"km"}
                    label={"Km"}
                    value={car.general.km}
                    onChange={handleChange}
                  />
                  <TextField
                    className="textField"
                    key={"precio"}
                    name={"precio"}
                    label={"Precio"}
                    value={car.general.precio}
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    className="textField"
                    control={
                      <Checkbox
                        checked={car.general.existence}
                        onChange={handleCheckboxChange}
                        name="existence"
                        color="rojoYBlanco"
                      />
                    }
                    label="Existencia"
                  />
                </div>
              </div>
              <div>
                <h2>Detalles Adicionales</h2>
                <div className="textFields2">
                  {createSwitchFields(car.detallesAdicionales)}
                </div>
              </div>
            </div>
            <div>
              <FileInput
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            </div>
            <Button className="button" color="negroYBlanco" type="submit">
              {isLoading ? <CircularProgress size={24} /> : "Agregar Coche"}
            </Button>
          </form>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
          />
          {/* Un boton para pruebas de inyeccion de carros a la BDD */}
          {/*
           <Button
            className="button"
            color="primary"
            disabled={isLoading}
            onClick={handleTestInjectClick}
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Inyectar Prueba de Coche"
            )}
          </Button>
           */}
        </div>
      </div>
    </div>
  );
};

export default AgregarAuto;
