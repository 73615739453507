// Importaciones de componentes y funciones necesarias
import Button from "@mui/material/Button"; // Botón de Material UI
import Dialog from "@mui/material/Dialog"; // Diálogo (modal) de Material UI
import DialogActions from "@mui/material/DialogActions"; // Contenedor para acciones del diálogo
import DialogContent from "@mui/material/DialogContent"; // Contenido principal del diálogo
import DialogContentText from "@mui/material/DialogContentText"; // Texto dentro del diálogo
import DialogTitle from "@mui/material/DialogTitle"; // Título del diálogo
import Link from "@mui/material/Link"; // Componente de enlace estilizado
import { getAuth, signOut } from "firebase/auth"; // Funciones de autenticación de Firebase
import React, { useEffect, useState } from "react"; // Hooks de React para estado y efectos
import { Timer } from "../firebase/Timer"; // Componente personalizado para manejar un temporizador

// Definición del componente funcional AdminNavbar
const AdminNavbar = () => {
  const auth = getAuth(); // Inicialización del servicio de autenticación de Firebase
  const [openDialog, setOpenDialog] = useState(false); // Estado para controlar la visibilidad del diálogo
  const [timeoutPeriod, setTimeoutPeriod] = useState(1800000); // Estado para el período de tiempo antes de ejecutar una acción, configurado inicialmente a 30 minutos

  // Efecto para resetear el temporizador al montar el componente
  useEffect(() => {
    setTimeoutPeriod(1800000); // Resetear el periodo de tiempo a 30 minutos
  }, []);

  // Función para manejar la acción de ir a la página de inicio
  const handleGoHome = () => {
    setOpenDialog(true); // Abre el diálogo de confirmación
  };

  // Función para cerrar el diálogo de confirmación
  const handleCloseDialog = () => {
    setOpenDialog(false); // Cierra el diálogo
  };

  // Función para confirmar la acción de ir a la página de inicio y realizar el redireccionamiento
  const handleConfirmGoHome = () => {
    window.location.href = "/"; // Redirecciona a la página de inicio
  };

  // Función para manejar el cierre de sesión
  const handleLogOut = () => {
    signOut(auth) // Intenta cerrar sesión
      .then(() => {
        window.location.href = "/admin";
      })
      .catch((error) => {
        console.error("Error signing out:", error); // Log en consola si hay un error al cerrar sesión
      });
  };

  // Función para manejar el tiempo de espera (timeout)
  const handleTimeout = () => {
    signOut(auth).then(() => {
      window.location.href = "/admin"; // Redirecciona a la página de inicio de sesión después de cerrar sesión
    });
  };

  // Array de objetos que define los enlaces de la barra de navegación
  const links = [
    { href: "/admin/agregar-auto", label: "Agregar Auto" },
    { href: "/admin/editar-auto", label: "Editar Auto" },
    { label: "Landing Page", onClick: handleGoHome },
    { href: "/", label: "Cerrar Sesion", onClick: handleLogOut },
  ];

  // Renderización del componente
  return (
    <div className="nav">
      <a href="/admin/manager" key={"xd"}>
        <img className="nav-logo" src="/logo-01.png" alt="logo uwu" />
      </a>
      <div className="nav-boxLinks">
        <Timer timeoutPeriod={timeoutPeriod} onTimeout={handleTimeout} />
        {links.map(({ href, label, onClick }) => (
          <Link
            className="nav-links"
            href={href}
            color="inherit"
            underline="none"
            onClick={onClick}
            key={href}
          >
            {label}
          </Link>
        ))}
      </div>
      {/* Diálogo de confirmación */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"No ha cerrado sesión"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Desea continuar sin cerrar sesión?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="rojoYBlanco">
            Cancelar
          </Button>
          <Button onClick={handleConfirmGoHome} color="rojoYBlanco" autoFocus>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminNavbar;
