import React from "react";
import MyNavbar from "../components/NavBar";
import Footer from "../components/Footer";
import CarGallery from "../components/CarGallery";

export const ComprarAuto = () => {
  return (
    <div>
      <MyNavbar />
      <div>
        <div className="content">
          <CarGallery />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ComprarAuto;
