/* Import */
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import { mainTheme } from "./Themes.js";
import ProtectedRoute from "./firebase/ProtectedRoute.js";

/* Import pages*/
import Home from "./pages/Home.js";
import Contacto from "./pages/Contacto.js";
import Ubicacion from "./pages/Ubicacion.js";
import ContenidoAdicional from "./pages/ContenidoAdicional.js";
import ComprarAuto from "./pages/ComprarAuto.js";
import Manager from "./pages/admin/Manager.js";
import SignIn from "./pages/admin/SignIn.js";
import AgregarAuto from "./pages/admin/AgregarAuto.js";
import EditarAuto from "./pages/admin/EditarAuto.js";
import ComprarAutoByID from "./pages/ComprarAutoByID.js";
import EditarAutoById from "./pages/admin/EditarAutoById.js";
import NotFoundPage from "./pages/NotFoundPage.js";

function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/comprar-auto" element={<ComprarAuto />} />
            <Route path="/comprar-auto/:id" Component={ComprarAutoByID} />
            <Route path="/contenido" element={<ContenidoAdicional />} />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="/ubicacion" element={<Ubicacion />} />
            <Route path="/admin" element={<SignIn />} />
            <Route
              path="/admin/manager"
              element={
                <ProtectedRoute>
                  <Manager />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/agregar-auto"
              element={
                <ProtectedRoute>
                  <AgregarAuto />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/editar-auto"
              element={
                <ProtectedRoute>
                  <EditarAuto />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/editar-auto/:id"
              element={
                <ProtectedRoute>
                  <EditarAutoById />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
