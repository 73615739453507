import React, { useEffect, useState } from "react";
import { IconButton, Typography, Button } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FilterBar from "./FilterDrawer";
import SearchBar from "../components/SearchBar";
import { getSomeCars, getCountCars } from "../api";
import SpinnerLlanta from "./SpinnerLlanta";
import CarCard from "./CarCardEdit";

const CarGalleryEdit = () => {
  const [carsData, setCarsData] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 12;
  const [selectedFilters, setSelectedFilters] = useState([]);

  // Función para manejar los filtros seleccionados desde FilterFolders
  const handleFilters = (filters) => {
    setSelectedFilters(filters);
    setCurrentPage(1);
  };

  // Reiniciar filtros y limpiar el estado de la navegación
  const handleResetFilters = () => {
    setSelectedFilters({});
  };

  useEffect(() => {
    const fetchCars = async () => {
      setIsLoading(true);
      try {
        const response = await getSomeCars(
          currentPage,
          resultsPerPage,
          selectedFilters,
          true,
        );
        // Reemplaza los datos existentes con los nuevos para la página actual
        setCarsData(response);
      } catch (error) {
        console.error("Error fetching cars:", error);
        setCarsData([]);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchTotalResults = async () => {
      try {
        const total = await getCountCars(selectedFilters, true);
        setTotalResults(total);
      } catch (error) {
        console.error("Error fetching total car count:", error);
        setTotalResults(0);
      }
    };

    fetchCars();
    fetchTotalResults();
  }, [currentPage, selectedFilters]); // Dependencia del useEffect ahora incluye currentPage
  // Este efecto se ejecuta solo una vez, cuando el componente se monta
  const displayedResults = Math.min(currentPage * resultsPerPage, totalResults);

  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  const nextPage = () => {
    // Calcula el total de páginas disponibles basado en totalResults y resultsPerPage
    const totalPages = Math.ceil(totalResults / resultsPerPage);

    // Solo incrementa currentPage si no estamos en la última página
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const previousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <div>
      {
        //<SearchBar onSearch={handleSearch} />
      }
      <div className="carGallery">
        {/* Barra superior */}
        <div className="barraSuperior">
          <div className="filter">
            <IconButton
              color="rojoYBlanco"
              onClick={toggleFilters}
              style={{
                transform: showFilters ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            >
              <FilterListIcon />
            </IconButton>
            <Typography color="blancoYNegro.light">{`Filtros`}</Typography>
          </div>
          <Typography color="blancoYNegro.light">{`${displayedResults} de ${totalResults} en total encontrados`}</Typography>
        </div>

        {/* Contenido principal */}
        <div className="container">
          {showFilters ? (
            <FilterBar
              onApplyFilters={handleFilters}
              showFilters={true}
              onResetFilters={handleResetFilters}
            />
          ) : null}
          {/* Asume que FilterBar se ajustará correctamente */}
          {isLoading ? (
            <SpinnerLlanta />
          ) : (
            <div className="cardsContainer">
              {carsData.map((car, index) => (
                <CarCard
                  key={index}
                  imgURL={car.imgURL}
                  title={car.title}
                  description={car.description}
                  id={car.id}
                  precio={car.precio}
                />
              ))}
            </div>
          )}
        </div>

        {/* Navegación de página */}
        <div className="navPages">
          <Button onClick={previousPage} disabled={currentPage === 1}>
            <ArrowBackIosNewIcon
              style={{ color: currentPage === 1 ? "#b2b2b2" : "#bd2128" }}
            />
          </Button>
          <Typography variant="body1" style={{ margin: "0 20px" }}>
            {currentPage}
          </Typography>
          <Button
            onClick={nextPage}
            disabled={currentPage >= Math.ceil(totalResults / resultsPerPage)}
          >
            <ArrowForwardIosIcon
              style={{
                color:
                  currentPage >= Math.ceil(totalResults / resultsPerPage)
                    ? "#b2b2b2"
                    : "#bd2128",
              }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CarGalleryEdit;
