// Importaciones necesarias de React, React Router DOM y Material-UI
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, useTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// El componente CarCard acepta props para personalizar su contenido
export default function CarCard(props) {
  // Desestructuración de props para extraer imgURL, title, description, y id
  const { imgURL, title, description, id, precio } = props;

  // useTheme permite acceder al tema actual para estilización
  const theme = useTheme();
  // useNavigate proporciona una función para programar la navegación entre rutas
  const navigate = useNavigate();

  // Estado local para manejar el efecto hover sobre la tarjeta
  const [isHovered, setIsHovered] = React.useState(false);

  // Función que se llama al hacer clic en la tarjeta, navegando a una URL específica
  const handleCardClick = () => {
    navigate(`/admin/editar-auto/${id}`);
  };

  // Renderización del componente
  return (
    <Card sx={{ width: 300, height: 300, position: "relative" }}>
      <CardActionArea
        sx={{
          height: "100%",
          overflow: "hidden",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleCardClick}
      >
        <CardMedia
          component="img"
          image={imgURL}
          alt={Array.isArray(title) ? title[0] : title}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transition: "opacity 0.3s",
          }}
        />
        <CardContent
          color="var(--mainColor)"
          sx={{
            position: "absolute",
            bottom: 0, // Posición ajustada para mostrar en la parte superior
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: alpha(theme.palette.rojoYBlanco.main, 0.8), // Usa un color rojo del tema
            padding: "1rem",
            opacity: isHovered ? 0 : 1, // Solo cambia la opacidad basada en isHovered
          }}
        >
          <Typography
            gutterBottom
            variant="body1"
            sx={{ margin: "0" }}
            fontWeight={500}
            fontSize={19}
            color="blancoYNegro.main"
          >
            {title}
          </Typography>
          <ExpandMoreIcon
            color="inherit"
            sx={{
              transform: isHovered ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 0.3s",
              color: "var(--mainColor)",
            }}
          />
        </CardContent>
        <CardContent
          sx={{
            padding: "20px",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: alpha(theme.palette.rojoYBlanco.main, 0.8),
            transition: "opacity 0.3s",
            overflow: "hidden",
            opacity: isHovered ? 1 : 0, // Solo cambia la opacidad basada en isHovered
            margin: 0,
          }}
        >
          {Array.isArray(title) ? (
            title.map((line, index) => (
              <Typography
                key={index}
                gutterBottom
                variant="body1"
                fontWeight={500}
                fontSize={19}
                color="blancoYNegro.main"
              >
                {line}
              </Typography>
            ))
          ) : (
            <Typography
              gutterBottom
              variant="body1"
              sx={{ margin: "0" }}
              fontWeight={500}
              fontSize={19}
              color="blancoYNegro.main"
            >
              {title}
            </Typography>
          )}
          {Array.isArray(description) ? (
            description.map((line, index) => (
              <Typography
                key={index}
                variant="body2"
                fontWeight={500}
                fontSize={14}
                color="blancoYNegro.main"
              >
                {line}
              </Typography>
            ))
          ) : (
            <Typography
              variant="body2"
              fontWeight={500}
              fontSize={14}
              color="blancoYNegro.main"
            >
              {description}
            </Typography>
          )}
          {Array.isArray(precio) ? (
            precio.map((line, index) => (
              <Typography
                key={index}
                variant="body2"
                fontWeight={500}
                fontSize={14}
                color="blancoYNegro.main"
              >
                {line}
              </Typography>
            ))
          ) : (
            <Typography
              variant="body2"
              fontWeight={500}
              fontSize={14}
              color="blancoYNegro.main"
            >
              ${precio}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
