import React, { useEffect, useRef, useState } from "react";
import MyNavbar from "../components/NavBar";
import Footer from "../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { getCarById, getCarrosInteresByCarroceria } from "../api";
import { Button, ThemeProvider, Typography, createTheme } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Carousel } from "react-carousel-minimal";
import ShareIcon from "@mui/icons-material/Share";
import { Divider } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SpeedIcon from "@mui/icons-material/Speed";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ConstructionIcon from "@mui/icons-material/Construction";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import SecurityIcon from "@mui/icons-material/Security";
import { GiCarSeat } from "react-icons/gi";
import { PiSteeringWheel } from "react-icons/pi";
import SpinnerLlanta from "../components/SpinnerLlanta";
import CarCard from "../components/CarCard";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";

export const ComprarAutoByID = () => {
  const { id } = useParams(); // Obtiene el ID del auto desde la URL
  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [carrosSimilares, setCarrosSimilares] = useState([]);
  const defaultImage = "/images/EjemploCoche.jpg";
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // Referencias a los contenedores
  const contenedorRef = useRef(null);
  const rightRef = useRef(null);

  const navigate = useNavigate();
  const [mensaje, setMensaje] = useState("");

  const theme = createTheme({
    typography: {
      // Define el color de texto por defecto para todos los Typography
      allVariants: {
        color: "#333333",
      },
    },
    palette: {
      rojoYBlanco: {
        main: "#bd2128",
        contrastText: "#ffffff",
      },
    },
  });

  // Función auxiliar para extraer el número de secuencia de una URL
  const getSequenceNumber = (url) => {
    const match = url.match(/-(\d+)\.[^\.]+$/);
    return match ? parseInt(match[1], 10) : -1;
  };

  useEffect(() => {
    const fetchCarData = async () => {
      setLoading(true);
      try {
        const fetchedCar = await getCarById(id);

        // Aquí, antes de actualizar el estado, ordenamos las fotos si existen
        if (fetchedCar && fetchedCar.photos) {
          const sortedPhotos = fetchedCar.photos.sort((a, b) => {
            return getSequenceNumber(a) - getSequenceNumber(b);
          });
          // Actualizamos el objeto fetchedCar con las fotos ordenadas
          fetchedCar.photos = sortedPhotos;
        }
        setCar(fetchedCar);
        if (fetchedCar) {
          const similares = await getCarrosInteresByCarroceria(id, 3);
          setCarrosSimilares(similares);
          setMensaje(
            `Quisiera agendar una cita para ver el ${fetchedCar.general.marca} ${fetchedCar.general.submarca} ${fetchedCar.general.version} ${fetchedCar.general.modelo}, color ${fetchedCar.general.color} que se encuentra en ${fetchedCar.general.ubicacion}.`,
          );
        }
      } catch (error) {
        setError("Error al cargar la información del auto");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCarData();
  }, [id]);

  // Asegurándose de tener al menos una imagen para el carrusel
  const carouselData =
    car?.photos?.length > 0
      ? car.photos.map((photoURL) => ({
          image: photoURL,
          caption: "",
        }))
      : [
          { image: defaultImage, caption: "" },
          { image: defaultImage, caption: "" },
          { image: defaultImage, caption: "" },
        ]; // Usa la imagen predeterminada si no hay fotos

  let whatsAppMessage = "Hola, estoy interesado en el carro ";
  if (car) {
    const { marca, submarca, version, modelo } = car.general;
    whatsAppMessage += `${marca}, ${submarca}, ${version}, ${modelo}. Mi nombre es: `;
    // Codifica el mensaje para URL
    whatsAppMessage = encodeURIComponent(whatsAppMessage);
    // Construye la URL completa para WhatsApp
    whatsAppMessage = `https://wa.me/+525519729384?text=${whatsAppMessage}`;
  }

  const handleAgendarCita = () => {
    navigate("/contacto", { state: { mensaje: mensaje } });
  };

  const handleShareClick = () => {
    navigator.clipboard
      .writeText(window.location.href) // Copia la URL actual al portapapeles
      .then(() => {
        setSnackbarOpen(true); // Abre el Snackbar tras copiar exitosamente
      })
      .catch((err) => {
        console.error("Error al copiar el enlace: ", err);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const adjustRightPosition = () => {
    if (!contenedorRef.current || !rightRef.current) return;

    const contenedor = contenedorRef.current;
    const right = rightRef.current;
    const contenedorRect = contenedor.getBoundingClientRect();

    // Posición fija cuando el contenedor está en el viewport
    if (
      contenedorRect.top < 200 &&
      contenedorRect.bottom > window.innerHeight
    ) {
      right.style.position = "fixed";
      right.style.top = "200px"; // Ajusta este valor según necesites
    } else {
      right.style.position = "absolute";
      if (contenedorRect.top >= 200) {
        // Si el contenedor todavía no ha llegado al punto de scroll, posiciona en la parte superior
        right.style.top = "0";
      } else {
        // Una vez que el contenedor se desplaza hacia arriba fuera del viewport, fija al fondo
        right.style.top = `${contenedor.offsetHeight - right.offsetHeight}px`;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", adjustRightPosition);
    window.addEventListener("resize", adjustRightPosition);

    adjustRightPosition(); // Ajustar al cargar y al redimensionar

    return () => {
      window.removeEventListener("scroll", adjustRightPosition);
      window.removeEventListener("resize", adjustRightPosition);
    };
  }, []);

  const handleClickOption = (filterLabel, option) => {
    // Navega al componente CarGallery pasando el filtro y el valor como estado
    navigate("/comprar-auto", {
      state: { filter: filterLabel, value: option },
    });
  };

  return (
    <div>
      <MyNavbar />
      <div className="body">
        <div className="content">
          {loading ? (
            <SpinnerLlanta />
          ) : error ? (
            <div>Error: {error}</div>
          ) : (
            <div className="carIdSpecifics">
              <div className="redireccion">
                <a
                  href="/comprar-auto"
                  style={{
                    textDecoration: "none",
                    textTransform: "none",
                    color: "inherit",
                  }}
                >
                  <Typography>Autos Seminuevos</Typography>
                </a>
                <NavigateNextIcon color="rojoYBlanco" />
                <a
                  onClick={() => handleClickOption("Marca", car.general.marca)}
                  style={{
                    textDecoration: "none",
                    textTransform: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  <Typography>{car.general.marca}</Typography>
                </a>
                <NavigateNextIcon color="rojoYBlanco" />
                <a
                  onClick={() => handleClickOption("Marca", car.general.marca)}
                  style={{
                    textDecoration: "none",
                    textTransform: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  <Typography>{car.general.submarca}</Typography>
                </a>

                <NavigateNextIcon color="rojoYBlanco" />
                <Typography>
                  {car.general.version} {car.general.modelo}
                </Typography>
              </div>
              <div className="column">
                <ThemeProvider theme={theme}>
                  <div className="left">
                    <div className="carousel">
                      {carouselData.length > 0 && (
                        <Carousel
                          data={carouselData}
                          time={2000}
                          width="100%"
                          height="auto"
                          radius="10px"
                          slideNumber={true}
                          captionPosition="bottom"
                          automatic={false}
                          dots={true}
                          pauseIconColor="white"
                          pauseIconSize="80px"
                          slideBackgroundColor="white"
                          slideImageFit="fit"
                          thumbnails={true}
                          thumbnailWidth="100px"
                          style={{
                            textAlign: "center",
                            width: "100%",
                            height: "auto",
                          }}
                        />
                      )}
                    </div>
                    <div className="carInfo">
                      <ThemeProvider theme={theme}>
                        <div className="titulo">
                          <div className="info">
                            <Typography fontWeight={700} fontSize="1.5rem">
                              {car.general.marca} {car.general.submarca}{" "}
                              {car.general.version}
                            </Typography>
                            <Typography>
                              {car.general.km}km - {car.general.ubicacion}
                            </Typography>
                          </div>
                          <IconButton
                            onClick={handleShareClick}
                            style={{ color: "var(--secondaryColor)" }}
                          >
                            <ShareIcon
                              style={{ width: "40px", height: "auto" }}
                            />
                          </IconButton>
                          <Snackbar
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            open={snackbarOpen}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackbar}
                            message="Copiado al portapapeles"
                          />
                        </div>
                        <Divider />
                        <div className="precio">
                          <Typography fontWeight={700} fontSize="1.3rem">
                            Precio de contado
                          </Typography>
                          <Typography fontWeight={700} fontSize="3rem">
                            ${car.general.precio}*
                          </Typography>
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              color: "green",
                            }}
                            href={whatsAppMessage}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Typography
                              alignItems="center"
                              style={{ color: "green" }}
                            >
                              Pregunta por nuestro plan a meses
                            </Typography>
                            <WhatsAppIcon />
                          </a>
                        </div>
                        <Divider />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div style={{ width: "50%" }}>
                            <Typography>Modelo</Typography>
                            <Typography fontWeight={700}>
                              {car.general.modelo}
                            </Typography>
                          </div>
                          <div style={{ width: "50%" }}>
                            <Typography>Versión</Typography>
                            <Typography fontWeight={700}>
                              {car.general.version}
                            </Typography>
                          </div>
                        </div>
                        <Divider />
                      </ThemeProvider>
                      <Button
                        variant="contained"
                        color="rojoYBlanco"
                        style={{ textTransform: "none", fontSize: "1.2rem" }}
                        onClick={handleAgendarCita}
                      >
                        Contactanos para una prueba de manejo
                      </Button>
                      <Typography fontWeight={500} fontSize="0.5rem">
                        * El precio puede cambiar o modificarse según la
                        cotización / financiamiento final, el año, modelo del
                        auto y/o promociones del mes en Pick Uplandia SA de CV.
                      </Typography>
                    </div>
                    <div className="informacionBasica">
                      <Typography fontSize="1.5rem" fontWeight={700}>
                        Información básica
                      </Typography>
                      <div className="infoCarBoxes">
                        {car &&
                          car.general &&
                          Object.entries(car.general).map(([key, value]) => {
                            // Mapeo de claves a nombres amigables para mostrar
                            const friendlyNames = {
                              marca: "Marca",
                              submarca: "Submarca",
                              version: "Versión",
                              modelo: "Modelo",
                              color: "Color",
                              transmision: "Transmisión",
                              combustible: "Combustible",
                              traccion: "Tracción",
                              km: "Kilometraje",
                              precio: "Precio",
                              existence: "Existencia", // Nombre amigable para "existence"
                              ubicacion: "Ubicación",
                              carroceria: "Carroceria",
                            };

                            // Formateo especial para 'existence'
                            if (key === "existence") {
                              value = value === 1 ? "Sí" : "No";
                            }
                            // Formateo especial para 'precio'
                            else if (key === "precio") {
                              value = `$${value} MXN`;
                            }
                            // Agregar "km" al final de 'km'
                            else if (key === "km") {
                              value = `${value} km`;
                            }

                            // Usa el nombre amigable para la clave si está disponible
                            const displayName = friendlyNames[key] || key;

                            return (
                              <div key={key} className="infoCarBox">
                                <Typography>{displayName}: </Typography>
                                <Typography fontWeight={700}>
                                  {value}
                                </Typography>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="detallesAdicionales">
                      <Typography
                        fontSize="1.5rem"
                        fontWeight={700}
                        style={{ marginBottom: "20px" }}
                      >
                        Detalles Adicionales
                      </Typography>
                      <div className="carBoxes">
                        {car &&
                          car.detallesAdicionales &&
                          Object.entries(car.detallesAdicionales).map(
                            ([categoria, detalles]) => (
                              <div className="infoCarBoxes" key={categoria}>
                                <div className="category">
                                  {categoria === "generales" && (
                                    <SpeedIcon className="icon" />
                                  )}
                                  {categoria === "exterior" && (
                                    <DirectionsCarIcon className="icon" />
                                  )}
                                  {categoria === "interior" && (
                                    <GiCarSeat className="icon" />
                                  )}
                                  {categoria === "equipamiento" && (
                                    <PiSteeringWheel className="icon" />
                                  )}
                                  {categoria === "seguridad" && (
                                    <SecurityIcon className="icon" />
                                  )}
                                  {categoria === "entretenimiento" && (
                                    <VolumeUpIcon className="icon" />
                                  )}

                                  <Typography
                                    fontSize="1.3rem"
                                    fontWeight={700}
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {categoria}{" "}
                                    {/* Muestra el nombre de la categoría */}
                                  </Typography>
                                </div>
                                <Divider />
                                <div className="subCategories">
                                  {Object.entries(detalles).map(
                                    ([detalleKey, detalleValue]) => {
                                      // Ignorar y no renderizar si el detalleValue es solo un espacio en blanco
                                      if (
                                        detalleValue === "" ||
                                        detalleValue == null
                                      )
                                        return null;
                                      // Transforma el detalleKey a un nombre amigable si es necesario
                                      const friendlyNames = {
                                        vidriosDelanteros: "Vidrios Delanteros",
                                        vidriosTraseros: "Vidrios Traseros",
                                        espejos: "Espejos",
                                        rines: "Rines",
                                        cajuela: "Cajuela",
                                        puertas: "Puertas",
                                        interior: "Interior",
                                        asientos: "Asientos",
                                        vestiduras: "Vestiduras",
                                        acabado: "Acabado",
                                        aireAcondicionado: "Aire Acondicionado",
                                        radioAMFM: "Radio AM y FM",
                                        computadoraDeViaje:
                                          "Comuptadora de Viaje",
                                        controlesAlVolante:
                                          "Controles al Volante",
                                        sensorTrasero: "Sensor Trasero",
                                        camaraTrasera: "Cámara Trasera",
                                        quemacocos: "Quemacocos",
                                        bocinas: "Bocinas",
                                        llave: "Llave",
                                        piloto: "Piloto",
                                        copiloto: "Copiloto",
                                        traseras: "Traseras",
                                        laterales: "Laterales",
                                        frenoABS: "Freno ABS",
                                        // Añade más nombres amigables según sea necesario
                                      };

                                      const displayName =
                                        friendlyNames[detalleKey] ||
                                        detalleKey.charAt(0).toUpperCase() +
                                          detalleKey.slice(1); // Usa el nombre amigable para el detalleKey si está disponible

                                      if (
                                        categoria === "equipamiento" ||
                                        categoria === "seguridad"
                                      ) {
                                        return (
                                          <div
                                            key={detalleKey}
                                            className="infoSubCategories"
                                          >
                                            <Typography>
                                              {displayName}
                                            </Typography>
                                            <Typography fontWeight={700}>
                                              Si
                                            </Typography>
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div
                                            key={detalleKey}
                                            className="infoSubCategories"
                                          >
                                            <Typography>
                                              {displayName}:{" "}
                                            </Typography>
                                            <Typography fontWeight={700}>
                                              {detalleValue}
                                            </Typography>
                                          </div>
                                        );
                                      }
                                    },
                                  )}
                                </div>
                              </div>
                            ),
                          )}
                      </div>
                    </div>
                  </div>
                </ThemeProvider>
                <div ref={contenedorRef} className="right">
                  <div ref={rightRef} className="carInfo">
                    <ThemeProvider theme={theme}>
                      <div className="titulo">
                        <div className="info">
                          <Typography fontWeight={700} fontSize="1.5rem">
                            {car.general.marca} {car.general.submarca}{" "}
                            {car.general.version}
                          </Typography>
                          <Typography>
                            {car.general.km}km - {car.general.ubicacion}
                          </Typography>
                        </div>
                        <IconButton
                          onClick={handleShareClick}
                          style={{ color: "var(--secondaryColor)" }}
                        >
                          <ShareIcon
                            style={{ width: "40px", height: "auto" }}
                          />
                        </IconButton>
                        <Snackbar
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          open={snackbarOpen}
                          autoHideDuration={6000}
                          onClose={handleCloseSnackbar}
                          message="Copiado al portapapeles"
                        />
                      </div>
                      <Divider />
                      <div className="precio">
                        <Typography fontWeight={700} fontSize="1.3rem">
                          Precio de contado
                        </Typography>
                        <Typography fontWeight={700} fontSize="3rem">
                          ${car.general.precio}*
                        </Typography>
                        <a
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "green",
                          }}
                          href={whatsAppMessage}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Typography
                            alignItems="center"
                            style={{ color: "green" }}
                          >
                            Pregunta por nuestro plan a meses
                          </Typography>
                          <WhatsAppIcon />
                        </a>
                      </div>
                      <Divider />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <Typography>Modelo</Typography>
                          <Typography fontWeight={700}>
                            {car.general.modelo}
                          </Typography>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Typography>Versión</Typography>
                          <Typography fontWeight={700}>
                            {car.general.version}
                          </Typography>
                        </div>
                      </div>
                      <Divider />
                    </ThemeProvider>
                    <Button
                      variant="contained"
                      color="rojoYBlanco"
                      style={{ textTransform: "none", fontSize: "1.2rem" }}
                      onClick={handleAgendarCita}
                    >
                      Contactanos para una prueba de manejo
                    </Button>
                    <Typography fontWeight={500} fontSize="0.5rem">
                      * El precio puede cambiar o modificarse según la
                      cotización / financiamiento final, el año, modelo del auto
                      y/o promociones del mes en Pick Uplandia SA de CV.
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="carrosSimilares">
                <Typography fontWeight="700" fontSize="2rem">
                  Otros autos que te podrian interesar.
                </Typography>
                <div className="cards">
                  {carrosSimilares.map((carroSimil) => (
                    <CarCard key={carroSimil.id} {...carroSimil} /> // Asegúrate de que CarCard acepte y use correctamente las props
                  ))}
                </div>
              </div>
            </div>
          )}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ComprarAutoByID;
