import React from "react";

const SpinnerLlanta = () => {
  return (
    <div className="spinner-container">
      <img
        src="/images/llanta.png"
        alt="Cargando..."
        className="spinner-llanta"
      />
    </div>
  );
};

export default SpinnerLlanta;
