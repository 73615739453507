import React, { useEffect, useState } from "react";
import CarCard from "./CarCard";
import SpinnerLlanta from "./SpinnerLlanta";
import { getRandomCars } from "../api";
import { Typography } from "@mui/material";

export const DestacadosSemana = () => {
  const [randomCars, setRandomCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRandomCars = async () => {
      try {
        const cars = await getRandomCars(3); // Obtiene 3 coches aleatorios
        setRandomCars(cars);
        setLoading(false);
      } catch (err) {
        setError("No se pudieron cargar los coches aleatorios");
        setLoading(false);
        console.error(err);
      }
    };

    fetchRandomCars();
  }, []);

  if (loading)
    return (
      <div>
        <SpinnerLlanta />
      </div>
    );
  return (
    <div className="destacados">
      <Typography variant="h4">Destacados de la semana</Typography>
      <div className="cards">
        {randomCars.map((car) => {
          return (
            <CarCard
              key={car.id}
              imgURL={car.imgURL || "/images/defaultCarImage.jpg"} // Asegúrate de tener una imagen por defecto
              title={car.title}
              description={car.description}
              id={car.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DestacadosSemana;
