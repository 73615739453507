import React from "react";
import MyNavbar from "../components/NavBar";
import FilterBarHome from "../components/FilterBarHome";
import Footer from "../components/Footer";
import DestacadosSemana from "../components/DestacadosSemana";
import { Button, Typography } from "@mui/material";
//import { useNavigate } from "react-router-dom";

export const Home = () => {
  /*
  const navigate = useNavigate(); // Agregar esta línea

  let message = "Hola, estoy interesado en ";

  const handlePruebaManejo = () => {
    message += `una prueba de manejo. Me interesa el coche...`;
    handleAgendarCita(message);
  };

  const handleVenderAuto = () => {
    message += `vender o cambiar mi auto... `;
    handleAgendarCita(message);
  };

  const handleAgendarCita = (mensaje) => {
    console.log("mensaje", mensaje);

    navigate("/contacto", { state: { mensaje: mensaje } });
  };

  */
  return (
    <div>
      <MyNavbar />
      <div className="content">
        <div>
          <FilterBarHome />
          <div
            className="bannerBienvenida"
            style={{
              display: "flex",
              height: "400px",
              width: "100%",
              position: "relative",
            }}
          >
            <div class="quarter-circle-top-left"></div>
            <div class="quarter-circle-bottom-right"></div>
            <div
              className="leftText"
              style={{
                display: "flex",
                width: "auto",
                minWidth: "300px",
                //backgroundColor: "rgba(255, 0, 0, 0.5)",
                zIndex: "4",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingLeft: "2vw",
              }}
            >
              <div
                className="texto"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <Typography fontSize={"3rem"}>Más que un coche,</Typography>
                <div
                  style={{ display: "flex", flexWrap: "nowrap", gap: "1rem" }}
                >
                  <Typography fontSize={"3rem"}>una</Typography>
                  <Typography
                    fontSize={"3rem"}
                    style={{ color: "var(--secondaryColor)" }}
                    fontWeight={700}
                  >
                    experiencia
                  </Typography>
                </div>
                <Button
                  className="button1"
                  variant="contained"
                  color="rojoYBlanco"
                  size="large"
                  href="/comprar-auto"
                  style={{
                    textTransform: "none",
                    fontSize: "1.3rem",
                    width: "fit-content",
                  }}
                >
                  Visita nuestro catálogo!
                </Button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                //backgroundColor: "rgba(255, 255, 0, 0.5)",
                width: "calc(100% - 500px)", // El amarillo ocupa el espacio restante
                minWidth: "650px", // Mínimo de 400px
                position: "absolute", // Posicionamiento absoluto para permitir el solapamiento
                top: 0,
                right: 0, // Posicionamos el div amarillo a la derecha del rojo
                height: "100%",
                zIndex: 1, // Aseguramos que el amarillo quede detrás del rojo
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  display: "flex",
                  //backgroundColor: "rgba(0, 0, 255, 0.5)",
                  width: "600px",
                  minWidth: "650px",
                  height: "auto",
                  zIndex: 1,
                  paddingTop: "3rem",
                }}
              >
                <div
                  className="coches"
                  style={{
                    display: "flex",
                    flexWrap: "noWrap",
                    alignItems: "center",
                    gap: "2rem",
                    position: "relative",
                    paddingLeft: "5rem",
                  }}
                >
                  {["/images/CocheRojo.png", "/images/Camioneta.png"].map(
                    (src) => (
                      <div style={{ position: "relative" }}>
                        <img
                          src={src}
                          style={{
                            width: "240px", // Aumenta el ancho para compensar el desplazamiento izquierdo
                            transform: "skew(-10deg)",
                            height: "auto",
                          }}
                          alt="Auto de Ejemplo"
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "4px",
                            left: "-10px",
                            right: "10px",
                            bottom: "-4px",
                            border: "2px solid red",
                            transform: "skew(-10deg)",
                            pointerEvents: "none", // Ensure clicks pass through
                          }}
                        ></div>
                      </div>
                    ),
                  )}
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  display: "flex",
                  //backgroundColor: "rgba(0, 255, 0, 0.5)",
                  width: "500px",
                  height: "auto",
                  zIndex: 2,
                }}
              >
                <img
                  src="/images/Pickup1.png"
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  alt="Pickup ejemplo"
                />
              </div>
            </div>
            <div className="efecto">{}</div>
          </div>
          <div
            className="banner2"
            style={{
              position: "relative",
              display: "flex",
              height: "400px",
              width: "100%",
              backgroundColor: "var(--secondaryColor)",
              alignItems: "center",
              gap: "1.4rem",
            }}
          >
            <div class="quarter-circle-top-left"></div>
            <div class="quarter-circle-bottom-right"></div>
            <div className="texto">
              <Typography
                className="renglon"
                fontSize={"3.5rem"}
                fontWeight={700}
              >
                Seminuevos
              </Typography>
              <Typography
                className="renglon"
                fontSize={"3.5rem"}
                fontWeight={700}
              >
                al mejor precio
              </Typography>
              <Typography className="renglon" fontSize={"2rem"}>
                y en las mejores condiciones
              </Typography>
            </div>
            <img
              src="/images/Pickup2.png"
              style={{
                width: "600px",
                height: "auto",
                zIndex: "1",
              }}
              alt="Pickup ejemplo"
            />
            <div className="efecto">{}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">
              Visita y explora nuestro catálogo
            </Typography>
            <Button
              color="rojoYBlanco"
              variant="contained"
              size="large"
              sx={{ textTransform: "none", fontSize: "1.3rem" }}
              href="/comprar-Auto"
            >
              Ver catálogo
            </Button>
          </div>
          <DestacadosSemana />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;

/*
<div className="banner-Bienvenida">
            <div
              style={{
                padding: "2rem",
                paddingLeft: "5rem",
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <div
                className="textoYBoton"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "26rem",
                  zIndex: "4",
                }}
              >
                <div className="texto">
                  <Typography fontSize={"3rem"}>Más que un coche,</Typography>
                  <div
                    style={{ display: "flex", flexWrap: "nowrap", gap: "1rem" }}
                  >
                    <Typography fontSize={"3rem"}>una</Typography>
                    <Typography
                      fontSize={"3rem"}
                      style={{ color: "var(--secondaryColor)" }}
                      fontWeight={700}
                    >
                      experiencia
                    </Typography>
                  </div>
                </div>
                <Button
                  className="button1"
                  variant="contained"
                  color="rojoYBlanco"
                  size="small"
                  onClick={handlePruebaManejo}
                >
                  Visita nuestro catálogo!
                </Button>
              </div>
              <div
                className="imagenesCoches"
                style={{
                  display: "flex",
                  flexGrow: "1",
                  justifyContent: "flex-end",
                  zIndex: "1",
                }}
              >
                <div
                  className="coches"
                  style={{
                    display: "flex",
                    flexWrap: "noWrap",
                    alignItems: "center",
                    gap: "2rem",
                    position: "relative",
                    paddingLeft: "5rem",
                  }}
                >
                  {["/images/CocheRojo.png", "/images/Camioneta.png"].map(
                    (src) => (
                      <div style={{ position: "relative" }}>
                        <img
                          src={src}
                          style={{
                            width: "240px", // Aumenta el ancho para compensar el desplazamiento izquierdo
                            transform: "skew(-10deg)",
                            height: "auto",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "4px",
                            left: "-10px",
                            right: "10px",
                            bottom: "-4px",
                            border: "2px solid red",
                            transform: "skew(-10deg)",
                            pointerEvents: "none", // Ensure clicks pass through
                          }}
                        ></div>
                      </div>
                    ),
                  )}
                </div>
                <img
                  src="/images/Pickup1.png"
                  style={{
                    width: "400px",
                    height: "auto",
                    position: "relative",
                    zIndex: "2",
                  }}
                />
              </div>
            </div>
          </div>
*/
