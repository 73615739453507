// carState.js
export const initialCarState = {
  general: {
    marca: "",
    submarca: "",
    version: "",
    modelo: "",
    carroceria: "",
    color: "",
    transmision: "",
    combustible: "",
    traccion: "",
    km: "",
    precio: "",
    existence: "",
    ubicacion: "",
  },
  detallesAdicionales: {
    generales: {
      vidriosDelanteros: "",
      vidriosTraseros: "",
      espejos: "",
    },
    exterior: {
      rines: "",
      puertas: "",
    },
    interior: {
      asientos: "",
      vestiduras: "",
    },
    equipamiento: {
      aireAcondicionado: "",
      radioAMFM: "",
      bluetooth: "",
      computadoraDeViaje: "",
      controlesAlVolante: "",
      sensorTrasero: "",
      camaraTrasera: "",
      quemacocos: "",
      bocinas: "",
    },
    seguridad: {
      llaveDePresencia: "",
      frenoABS: "",
      piloto: "",
      copiloto: "",
      traseras: "",
      laterales: "",
    },
  },
};
