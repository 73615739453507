import React from "react";
import { useDropzone } from "react-dropzone";

const FileInput = ({ selectedFiles, setSelectedFiles }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setSelectedFiles([...selectedFiles, ...acceptedFiles]);
    },
  });

  // Función para manejar la eliminación de un archivo específico
  const removeFile = (event, file) => {
    event.stopPropagation();
    setSelectedFiles(selectedFiles.filter((item) => item !== file));
  };

  return (
    <div {...getRootProps()} style={getStyle(isDragActive)}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Arrastra las imagenes aqui ...</p>
      ) : (
        <p>Arrastra las imagenes del Coche Aqui! o Click para abrir</p>
      )}
      {selectedFiles.length > 0 && (
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index}>
              {file.name}
              <button
                type="button"
                onClick={(event) => removeFile(event, file)}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                Eliminar
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const getStyle = (isDragActive) => ({
  border: "2px dashed #ccc",
  borderRadius: "5px",
  padding: "20px",
  textAlign: "center",
  color: isDragActive ? "green" : "inherit",
});

export default FileInput;
