import React from "react";
import MyNavbar from "../components/NavBar";
import Footer from "../components/Footer";

export const ContenidoAdicional = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space_between",
      }}
    >
      <MyNavbar />
      <div className="content" style={{ height: "100% " }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            height: "500px",
            flexDirection: "column",
          }}
        >
          <h1>Proximamente</h1>
          <img src="/logo-01.png" alt="" />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ContenidoAdicional;

/*return (
    <div>
      <MyNavbar />
      <div className="content">
        <div className="blogTestimonio">
          <div className="blog">
            <h1>PickUp Blog</h1>
            <div className="cartas"></div>
          </div>
          <div className="testimonio">
            <h1>Testimonios de nuestros clientes</h1>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  ); */
