// Importaciones de React y componentes específicos de Material-UI necesarios
import React from "react";
import { Autocomplete, TextField } from "@mui/material";

// Definición del componente funcional AutoCompleteField con props desestructuradas
const AutoCompleteField = ({ label, options, value, onChange }) => {
  // Función para manejar el cambio en el input del Autocomplete
  const handleInputChange = (event, newInputValue) => {
    // Llama a la función onChange proporcionada como prop con el nuevo valor ingresado
    onChange(newInputValue);
  };

  // Renderizado del componente Autocomplete de Material-UI
  return (
    <Autocomplete
      className="textField" // Clase CSS para estilización
      freeSolo // Permite introducir valores que no están en las opciones
      options={options.map((option) => option.name)} // Mapea las opciones a los nombres para la lista de autocompletar
      value={value || ""} // Establece el valor actual, manejando casos donde pueda ser nulo o indefinido
      onChange={(event, newValue) => {
        // Se llama cuando se selecciona una opción de la lista
        onChange(newValue); // Llama a onChange con el nuevo valor seleccionado
      }}
      onInputChange={handleInputChange} // Maneja el cambio en el valor del input
      renderInput={(params) => (
        // Función para renderizar el TextField interno
        <TextField
          {...params}
          label={label}
          variant="outlined"
          required
          color="rojoYBlanco"
        />
        // Utiliza las props pasadas para configurar el TextField, marcándolo como requerido y con un estilo delineado
      )}
    />
  );
};

export default AutoCompleteField; // Exporta el componente para su uso en otras partes de la aplicación
