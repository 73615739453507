import React from "react";

const NotFoundPage = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      {/* Ícono de coche chocado */}
      <img
        src="/images/CocheError404.png"
        style={{ height: "auto", width: "50%" }}
        alt="Error 404"
      />
      <h1>404 - Página no encontrada</h1>
      <p>Parece que esta página ha tenido un pequeño accidente.</p>
      <p>Lo sentimos, pero no podemos encontrar la página que buscas.</p>
      <p>
        ¿Quizás prefieras volver a la <a href="/">página principal</a>?
      </p>
    </div>
  );
};

export default NotFoundPage;
